// 文件上传类型
export const machineUploadType = [
  {
    attachmentType: "azqzp",
    typeName: "安装前照片",
    count: 1,
  },
  {
    attachmentType: "azhzp",
    typeName: "安装后照片",
    count: 1,
  },
  {
    attachmentType: "mpzp",
    typeName: "铭牌照片",
    count: 1,
  },
  {
    attachmentType: "zjwzzp",
    typeName: "主机位置照片",
    count: 1,
  },
  {
    attachmentType: "xszzp",
    typeName: "行驶证照片",
    count: 1,
  },
  {
    attachmentType: "jxyqc45dzp",
    typeName: "整车照片",
    count: 1,
  },
  {
    attachmentType: "ysd",
    typeName: "验收单",
    count: 1,
  },
  {
    attachmentType: "qtzp",
    typeName: "其它照片(可上传多个)",
    // 可以上传多个
    count: 0,
  },
];
