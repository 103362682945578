import { render, staticRenderFns } from "./ModalCarInfo2.vue?vue&type=template&id=4f479030&scoped=true"
import script from "./ModalCarInfo2.vue?vue&type=script&lang=js"
export * from "./ModalCarInfo2.vue?vue&type=script&lang=js"
import style0 from "./ModalCarInfo2.vue?vue&type=style&index=0&id=4f479030&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f479030",
  null
  
)

export default component.exports