<template>
  <el-dialog
    v-if="modal"
    :visible="modal"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :append-to-body="false"
    @close="onModalChange(false)"
    width="1100px"
  >
    <div slot="title" class="header-title">
      <span>{{ isEdit ? "编辑车辆" : "添加车辆" }}</span>
    </div>
    <div class="vehicle-info-content">
      <div class="content">
        <div class="basic">
          <div class="title">基本信息</div>
          <div class="form">
            <div class="item">
              <div class="label">设备性质：</div>
              <div class="info">
                <!-- <el-radio-group
                  v-model="vehicleInfo.isCommercialVehicles"
                  @change="hadnleChangeCommercialVehicles"
                >
                  <el-radio :label="true">商车</el-radio>
                  <el-radio :label="false">工机</el-radio>
                </el-radio-group> -->
                <div class="car-type-select">
                  <div
                    @click="hadnleChangeCommercialVehicles(true)"
                    :class="[
                      'car-type-select-item',
                      vehicleInfo.isCommercialVehicles
                        ? 'car-type-select-item-active'
                        : '',
                    ]"
                  >
                    商车
                  </div>
                  <div
                    @click="hadnleChangeCommercialVehicles(false)"
                    :class="[
                      'car-type-select-item',
                      vehicleInfo.isCommercialVehicles
                        ? ''
                        : 'car-type-select-item-active',
                    ]"
                  >
                    工机
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="label required">设备名称：</div>
              <div class="info">
                <el-input
                  v-model="vehicleInfo.plate"
                  @input="handleInput"
                  placeholder="请输入设备名称"
                ></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label required">所属车组：</div>
              <div class="info car-group-dropdown">
                <el-input
                  v-model="groupStr"
                  placeholder="请选择所属车组"
                  disabled
                >
                  <SelectGroupPopover
                    @nodeClick="onConfirmClick"
                    slot="append"
                  ></SelectGroupPopover>
                </el-input>
              </div>
            </div>
            <div class="item">
              <div class="label required">设备类型：</div>
              <div class="info">
                <el-select
                  style="width: 100%"
                  class="row-selectAddress"
                  v-model="vehicleInfo.machineryEquipmentType"
                  filterable
                  placeholder="请选择设备类型"
                  clearable
                >
                  <el-option
                    v-for="item in vehicleInfo.isCommercialVehicles
                      ? dictList.CXvehicleType
                      : dictList.vehicleType"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <!-- <div class="item">
              <div class="label required">设备种类：</div>
              <div class="info">
                <el-select
                  style="width: 100%"
                  class="row-selectAddress"
                  v-model="vehicleInfo.machineryEquipmentType"
                  filterable
                  placeholder="设备种类"
                  clearable
                >
                  <el-option
                    v-for="item in machineryEquipmentTypeList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </div>
            </div> -->
            <div class="item">
              <div class="label">型号：</div>
              <div class="info">
                <el-input
                  v-model.trim="vehicleInfo.vehicleModel"
                  placeholder="请输入型号"
                ></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">设备品牌：</div>
              <div class="info">
                <el-select
                  style="width: 100%"
                  v-model.trim="vehicleInfo.vehicleBrand"
                  filterable
                  clearable
                  placeholder="请选择设备品牌"
                >
                  <el-option
                    :value="item.dictKey"
                    :label="item.dictValue"
                    v-for="(item, index) in vehicleInfo.isCommercialVehicles
                      ? dictList.CXvehicleBrand
                      : dictList.vehicleBrand"
                    :key="item.dictKey"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="item">
              <div class="label required">机身编号(车架号)：</div>
              <div class="info">
                <el-input
                  placeholder="请输入机身编号(车架号)"
                  v-model.trim="vehicleInfo.frameNo"
                ></el-input>
              </div>
            </div>
            <!-- <div class="item">
              <div class="label">发动机编号：</div>
              <div class="info">
                <el-input v-model.trim="vehicleInfo.engineNo"></el-input>
              </div>
            </div> -->
            <div class="item">
              <div
                class="label"
                :class="{ required: vehicleInfo.isCommercialVehicles }"
              >
                车牌号：
              </div>
              <div class="info">
                <el-input
                  placeholder="请输入车牌号"
                  v-model.trim="vehicleInfo.sbPlate"
                ></el-input>
              </div>
            </div>
            <!-- <div class="item">
              <div class="label">出厂日期：</div>
              <div class="info">
                <el-date-picker
                  style="width: 100%"
                  v-model="vehicleInfo.productionDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </div>
            </div> -->
            <!-- <div class="item">
              <div class="label">整机重量：</div>
              <div class="info">
                <el-input v-model.number="vehicleInfo.seat"></el-input>
              </div>
            </div> -->
            <!-- <div class="item">
              <div class="label">外形尺寸(mm)：</div>
              <div class="info">
                <el-input v-model.trim="vehicleInfo.deviceSize"></el-input>
              </div>
            </div> -->
            <!-- <div class="item">
              <div class="label">购买日期：</div>
              <div class="info">
                <el-date-picker
                  style="width: 100%"
                  v-model="vehicleInfo.purchaseDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </div>
            </div> -->
            <!-- <div class="item">
              <div class="label">购买价格(元)：</div>
              <div class="info">
                <el-input v-model.trim="vehicleInfo.purchasePrice"></el-input>
              </div>
            </div> -->
            <!-- <div class="item">
              <div class="label">生产厂家：</div>
              <div class="info">
                <el-input
                  v-model.trim="vehicleInfo.manufactureFactory"
                ></el-input>
              </div>
            </div> -->
            <!-- <div class="item" style="width: 100%">
              <div class="label">备注内容：</div>
              <div class="info" style="width: 30%">
                <el-input
                  v-model="vehicleInfo.remark"
                  type="textarea"
                  :row="3"
                ></el-input>
              </div>
            </div> -->
            <div class="item">
              <div class="label">作业工况：</div>
              <div class="info">
                <el-select
                  style="width: 100%"
                  class="row-selectAddress"
                  v-model="vehicleInfo.workingConditionValue"
                  filterable
                  placeholder="请选择作业工况"
                  clearable
                >
                  <el-option
                    v-for="item in dictList.workingCondition"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="item">
              <div class="label required">所属客户：</div>
              <div class="info">
                <el-autocomplete
                  style="width: 100%"
                  v-model="vehicleInfo.customerName"
                  :fetch-suggestions="customerQuerySearchAsync"
                  @select="handleSelectCustomer"
                  placeholder="请选择所属客户"
                ></el-autocomplete>
              </div>
            </div>
            <div class="item">
              <div class="label">使用状态：</div>
              <div class="info">
                <el-radio-group v-model="vehicleInfo.onState">
                  <el-radio :label="1">正常</el-radio>
                  <el-radio :label="2">维修</el-radio>
                  <el-radio :label="3">停用</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div class="install">
          <div class="title">安装信息</div>
          <div class="form">
            <div class="item">
              <div class="label">安装类型：</div>
              <div class="info">
                <el-radio-group v-model="vehicleInfo.installationType">
                  <el-radio :label="1">公司</el-radio>
                  <el-radio :label="2">外包</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div v-if="vehicleInfo.installationType == 2" class="item">
              <div class="label required">安装服务商：</div>
              <div class="info">
                <el-input
                  placeholder="请输入安装服务商"
                  v-model.trim="vehicleInfo.installationServiceProvider"
                ></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label required">安装人员：</div>
              <div class="info">
                <el-input
                  placeholder="请输入安装人员"
                  v-model.trim="vehicleInfo.installPerson"
                ></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label required">安装时间：</div>
              <div class="info">
                <el-date-picker
                  v-model="vehicleInfo.installDate"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="安装时间"
                ></el-date-picker>
              </div>
            </div>
            <div class="item">
              <div class="label">服务费：</div>
              <div class="info">
                <el-input
                  placeholder="请输入服务费"
                  v-model.trim="vehicleInfo.serviceCharge"
                ></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">备注：</div>
              <div class="info">
                <el-input
                  placeholder="请输入安装人员"
                  type="textarea"
                  v-model.trim="vehicleInfo.remark"
                ></el-input>
              </div>
            </div>
            <!-- <div class="item">
              <div class="label">工单编号：</div>
              <div class="info">
                <el-input v-model="vehicleInfo.orderNumber"></el-input>
              </div>
            </div> -->
            <!-- <div class="item">
              <div class="label">激活时间：</div>
              <div class="info">
                <el-date-picker v-model="vehicleInfo.activationTme"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="激活时间">
                </el-date-picker>
              </div>
            </div>-->
          </div>
        </div>
        <div class="basic attachments">
          <div class="title">相关附件</div>
          <div class="attachments-tips">
            仅支持支持jpg、png、pdf、doc、docx格式
          </div>
          <div class="attachments-file">
            <div
              class="attachments-file-item"
              v-for="(item, index) in upLoadList"
              :key="index"
            >
              <div style="margin-bottom: 10px">{{ item.typeName }}：</div>
              <BoxUpload
                :count="item.count"
                :fileList="item.attachments"
                :multiple="!item.count"
              ></BoxUpload>
            </div>
          </div>
        </div>
        <div class="terminal">
          <div class="title">终端信息</div>
          <div class="form">
            <div class="item">
              <div class="label required">产品版本：</div>
              <div class="info">
                <el-select
                  v-model="vehicleInfo.vehicleVersion"
                  placeholder="请选择"
                  clearable
                  @change="handleTerminalSelect2"
                  value-key="id"
                >
                  <el-option
                    v-for="item in dictList.productList"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="terminal-info">
            <div
              class="info-item"
              v-for="(item, index) in vehicleInfo.terminalV2List"
              :key="index"
            >
              <div
                class="title"
                style="display: flex; justify-content: space-between"
              >
                <div style="display: flex; flex: 1">
                  <div>
                    <!-- <span v-if="item.deviceType === 1">视频终端</span>
                    <span
                      v-if="
                        item.deviceType === 2 &&
                        vehicleInfo.isCommercialVehicles
                      "
                      >AEB终端</span
                    >
                    <span v-else-if="item.deviceType === 2">OBD终端</span>
                    <span v-if="item.deviceType === 3">有线定位器</span> -->
                    <span>{{ item.deviceTypeName }}</span>
                    <el-tooltip
                      class="question-tip"
                      trigger="hover"
                      placement="top-start"
                    >
                      <div class="el-tooltip-content" slot="content">
                        <div>
                          1、视频类转部标类：成功后剩余到期时间将无法退币
                        </div>
                        <div>
                          2、部标类转视频类：成功后将扣除您对应的授权币数，后续续费将按视频类设备收费
                        </div>
                        <div>
                          3、部标类转视频类：如果当前账号余额不足，无法转换为视频类设备
                        </div>
                      </div>
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </div>
                  <span class="host2" v-if="item.mainFlag">主终端</span>
                </div>
                <div
                  style="cursor: pointer"
                  @click="innerVisible = true"
                  v-if="item.isVideo"
                >
                  设置摄像头
                </div>
              </div>
              <div class="form">
                <div class="item">
                  <div class="label required">终端编号：</div>
                  <div class="info">
                    <!-- <el-input
                      v-model="item.terminalNo"
                      @input="handleInput"
                    ></el-input> -->
                    <!-- <el-select
                      filterable
                      style="width: 100%"
                      v-model="vehicleInfo.terminalV2List[index]"
                      value-key="id"
                      @change="handleSelectTerminalV2($event, item, index)"
                    >
                      <el-scrollbar class="custom-scrollbar">
                        <el-option
                          :value="dic"
                          :label="dic.dictValue"
                          :key="dic.id"
                          v-for="(dic, i) in dictList[
                            `deviceTypeList${item.deviceType}${index}`
                          ]"
                        ></el-option>
                      </el-scrollbar>
                    </el-select> -->

                    <el-autocomplete
                      style="width: 100%"
                      :class="{ 'matching-box': isMatching }"
                      v-model="vehicleInfo.terminalV2List[index].terminalNo"
                      :fetch-suggestions="
                        (queryString, cb) => {
                          fetchTerminalV2(queryString, cb, item, index);
                        }
                      "
                      @select="handleSelectTerminalV2($event, item, index)"
                      placeholder="请选择终端"
                    ></el-autocomplete>
                  </div>
                </div>
                <div class="item">
                  <div class="label required">SIM卡号：</div>
                  <div class="info">
                    <el-input
                      style="width: 100%"
                      v-model="item.sim"
                      @input="handleInput"
                    ></el-input>
                  </div>
                </div>
                <!-- <div class="item">
                  <div class="label">工单编号：</div>
                  <div class="info">
                    <el-input v-model="item.orderNumber" @input="handleInput"></el-input>
                  </div>
                </div>-->
                <!-- <div class="item">
                  <div class="label">流量(GB)：</div>
                  <div class="info">
                    <el-input
                      v-model="item.datausage"
                      @input="handleInput"
                    ></el-input>
                  </div>
                </div> -->
                <div class="item">
                  <div class="label required">SIM卡供应商：</div>
                  <div class="info">
                    <el-select style="width: 100%" v-model="item.simTypeValue">
                      <el-option
                        :value="dic.dictKey"
                        :label="dic.dictValue"
                        v-for="(dic, index) in dictList.simCardType"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="item">
                  <div class="label required">激活日期：</div>
                  <div class="info">
                    <el-date-picker
                      style="width: 100%"
                      v-model="item.activationTime"
                      value-format="yyyy-MM-dd 00:00:00"
                      type="date"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                  </div>
                </div>
                <div class="item">
                  <div class="label required">服务到期：</div>
                  <div class="info">
                    <el-date-picker
                      style="width: 100%"
                      v-model="item.expireTime"
                      value-format="yyyy-MM-dd 00:00:00"
                      type="date"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="owner">
          <div class="title">车主信息</div>
          <div class="form">
            <div class="item">
              <div class="label">车主姓名：</div>
              <div class="info">
                <el-input
                  placeholder="请输入车主姓名"
                  v-model.trim="vehicleInfo.owner"
                ></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">联系电话：</div>
              <div class="info">
                <el-input
                  placeholder="请输入联系电话"
                  v-model.number="vehicleInfo.phone"
                ></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">紧急联系电话：</div>
              <div class="info">
                <el-input
                  placeholder="请输入紧急联系电话"
                  v-model.number="vehicleInfo.license"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-button type="primary" size="small" @click="submitData"
          >保存</el-button
        >
        <el-button size="small" @click="onModalChange(false)">取消</el-button>
      </div>
    </div>
    <el-dialog
      width="30%"
      title="设置摄像头"
      :visible.sync="innerVisible"
      append-to-body
    >
      <div slot="title" class="header-title">
        <span>设置摄像头</span>
      </div>
      <div class="item" style="width: 100%">
        <div class="label required">摄像头：</div>
        <div class="info">
          <draggable
            v-model="vehicleInfo.cameraArray"
            :options="{
              handle: '.mover',
            }"
          >
            <div
              style="display: flex; margin-bottom: 10px"
              v-for="(item, index) in vehicleInfo.cameraArray"
              :key="index"
            >
              <el-select v-model="item.cameraLine" class="camera-select">
                <el-option
                  v-for="camera in maxCameraNum"
                  :key="camera"
                  :value="camera"
                  :label="'摄像头' + camera"
                  >摄像头{{ camera }}</el-option
                >
              </el-select>
              <el-input
                v-model="item.cameraCH"
                style="width: 180px; margin: 0 10px"
                placeholder="请输入内容"
              ></el-input>
              <el-button
                type="danger"
                icon="el-icon-close"
                @click="handleDelCamera2(index)"
              ></el-button>
              <el-button
                type="primary"
                icon="el-icon-rank"
                style="#377DFF"
                class="mover"
              ></el-button>
            </div>
          </draggable>
        </div>
        <el-button
          type="primary"
          icon="el-icon-push"
          @click="handleDelCamera2(-1)"
          style="#377DFF"
          >追加</el-button
        >
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import dayjs from "dayjs";
import BoxUpload from "@/components/DragMultipleUpload/BoxUpload.vue";
import { machineUploadType } from "@/views/VehicleManagement/config.js";
import { getBrandList } from "@/api/getData";
import SelectGroupPopover from "@/components/SelectGroupPopover"; //1
import {
  getDictList, //获取产品列表
  getDictType, //获取设备列表
} from "@/api/getManagementData.js";
import { getVehicleInfo } from "@/api/live-vehicle-api";
import { addOrUpdateVehicle } from "@/api/getManagementData";
import draggable from "vuedraggable";
import {
  getVersionListByEquipmentNature,
  getTerminalListByEquipmentType,
  getEquipmentDetail,
  getEquipmentCustomerList,
  addOrEditEquipment,
} from "@/api/vehicleEquipment";
import {
  ProductTerminal,
  ProductTerminalCommerce,
} from "@/js/const/vehicle.js";
import { dictionaryBatch } from "@/api/policy";
export default {
  model: {
    prop: "modal",
    event: "change",
  },
  components: { SelectGroupPopover, draggable, BoxUpload },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    vehicleId: {
      type: Number,
      default: 0,
    },
    groupId: {
      type: Number,
      default: -1,
      required: false,
    },
    groupName: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      dictList: {
        productList: [],
        simCardType: [],
      },
      upLoadList: [],
      maxCameraNum: 12,
      innerVisible: false,
      isEdit: false,
      brandList: [], // 品牌列表
      vehicleInfo: this.initvehicleInfo(),
      groupStr: "",
      machineryEquipmentTypeList: [],
      machineryProductTypeList: [],
      cameras: [1, 2, 3, 4],
      terminalList: [
        {
          label: "智慧版mini",
          children: [
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 1,
              mainFlag: true,
              terminalType: "GB-1078",
              type: "video",
              label: "视频终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
          ],
        },
        {
          label: "智慧版",
          children: [
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 1,
              mainFlag: true,
              terminalType: "GB-1078",
              type: "video",
              label: "视频终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 2,
              mainFlag: false,
              type: "OBD",
              terminalType: "GPRS-部标",
              label: "OBD终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
          ],
        },
        {
          label: "智慧版plus",
          children: [
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 1,
              mainFlag: true,
              terminalType: "GB-1078",
              type: "video",
              label: "视频终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 2,
              mainFlag: false,
              type: "OBD",
              terminalType: "GPRS-部标",
              label: "OBD终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
          ],
        },
        {
          label: "鹰眼版",
          children: [
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 3,
              mainFlag: true,
              terminalType: "GPRS-部标",
              type: "wired",
              label: "有线定位器",
              datausage: "",
              manufactorType: "",
              unit: "MB",
            },
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 4,
              mainFlag: false,
              type: "wireless",
              terminalType: "GPRS-部标",
              label: "无线定位器",
              datausage: "",
              manufactorType: "",
              unit: "MB",
            },
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 4,
              mainFlag: false,
              type: "wireless",
              terminalType: "GPRS-部标",
              label: "无线定位器",
              datausage: "",
              manufactorType: "",
              unit: "MB",
            },
          ],
        },
        {
          label: "效能版",
          children: [
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 2,
              mainFlag: true,
              terminalType: "GPRS-部标",
              type: "OBD",
              label: "OBD终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
          ],
        },
      ],
      defaultTerminalList: [
        {
          label: "智慧版mini",
          children: [
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 1,
              mainFlag: true,
              terminalType: "GB-1078",
              type: "video",
              label: "视频终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
          ],
        },
        {
          label: "智慧版",
          children: [
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 1,
              mainFlag: true,
              terminalType: "GB-1078",
              type: "video",
              label: "视频终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 2,
              mainFlag: false,
              type: "OBD",
              terminalType: "GPRS-部标",
              label: "OBD终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
          ],
        },
        {
          label: "智慧版plus",
          children: [
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 1,
              mainFlag: true,
              terminalType: "GB-1078",
              type: "video",
              label: "视频终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 2,
              mainFlag: false,
              type: "OBD",
              terminalType: "GPRS-部标",
              label: "OBD终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
          ],
        },
        {
          label: "鹰眼版",
          children: [
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 3,
              mainFlag: true,
              terminalType: "GPRS-部标",
              type: "wired",
              label: "有线定位器",
              datausage: "",
              manufactorType: "",
              unit: "MB",
            },
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 4,
              mainFlag: false,
              type: "wireless",
              terminalType: "GPRS-部标",
              label: "无线定位器",
              datausage: "",
              manufactorType: "",
              unit: "MB",
            },
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 4,
              mainFlag: false,
              type: "wireless",
              terminalType: "GPRS-部标",
              label: "无线定位器",
              datausage: "",
              manufactorType: "",
              unit: "MB",
            },
          ],
        },
        {
          label: "效能版",
          children: [
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 2,
              mainFlag: true,
              terminalType: "GPRS-部标",
              type: "OBD",
              label: "OBD终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
          ],
        },
      ],
      scTerminalList: [
        {
          label: "AEB",
          children: [
            {
              terminalSim: "",
              terminalNo: "",
              deviceType: 2,
              mainFlag: true,
              terminalType: "GB-1078",
              type: "AEB",
              label: "AEB终端",
              datausage: "",
              manufactorType: "",
              unit: "GB",
            },
          ],
        },
      ],
      value1: null,
      cameraLineList: [
        {
          camera: 1,
          name: "右摄像头",
        },
        {
          camera: 2,
          name: "后摄像头",
        },
        {
          camera: 3,
          name: "左摄像头",
        },
        {
          camera: 4,
          name: "前摄像头",
        },
      ],
      isMatching: false,
    };
  },
  created() {
    this.dictionaryBatchGet();
  },
  watch: {
    modal: {
      immediate: true,
      handler: function (val) {
        // this.initvehicleInfo();
        this.isMatching = false;

        if (!val) return;
        this.handleFile();
        this.vehicleInfo.groupId = this.groupId;
        //this.vehicleInfo.groupName = this.groupName;
        // this.groupStr = this.groupName;
        if (!this.vehicleId) {
          this.changeEquipmentNature();
          return;
        }
        this.isEdit = true;
        this.getVehicleInfoByid(this.vehicleId);
      },
    },
  },
  computed: {},
  methods: {
    hadnleChangeCommercialVehicles(v) {
      this.vehicleInfo.isCommercialVehicles = v;
      this.changeEquipmentNatureClear();
      this.changeEquipmentNature(v);
      // if (v) {
      //   this.terminalList = JSON.parse(JSON.stringify(this.scTerminalList));
      // } else {
      //   this.terminalList = JSON.parse(
      //     JSON.stringify(this.defaultTerminalList)
      //   );
      // }
      // let temp = this.terminalList.find((ele) => {
      //   return ele.label === this.vehicleInfo.equipmentType;
      // });
      // this.vehicleInfo.curTerminal =
      //   temp || this.terminalList[1] || this.terminalList[0];
      // this.vehicleInfo.equipmentType = this.vehicleInfo.curTerminal.label;
      // // if (oldV === true || oldV === false) {
      // this.vehicleInfo.vehicleTerminals = JSON.parse(
      //   JSON.stringify(this.vehicleInfo.curTerminal.children)
      // );
      // }
    },
    getVehicleInfoByid(vehicleId) {
      getEquipmentDetail({ vehicleId: vehicleId }).then((res) => {
        this.changeEquipmentNature(res.obj.isCommercialVehicles);
        this.vehicleInfo = res.obj;
        this.groupStr = res.obj.groupName;
        //
        // this.vehicleInfo.terminalV2List.forEach(async (tv) => {
        //   await this.getTerminalListByDeviceType(tv.deviceType);
        // });
        //this.getMachineryEquipmentTypeList();
        this.handleFile(2);
        //this.handleCameraData(false);
        this.fillTerminalData(this.vehicleInfo.terminalV2List, false);
        this.getTerminalListByDeviceType2(true);
        this.$forceUpdate();
        //
        // if (this.vehicleInfo.isCommercialVehicles) {
        //   this.terminalList = JSON.parse(JSON.stringify(this.scTerminalList));
        // }
        // let temp = this.terminalList.find((ele) => {
        //   return ele.label === this.vehicleInfo.equipmentType;
        // });
        // this.vehicleInfo.curTerminal =
        //   temp || this.terminalList[1] || this.terminalList[0];
        //   this.vehicleInfo.vehicleTerminals = temp.children;
        // this.vehicleInfo.vehicleTerminals =
        //   res.obj.VehicleTerminals && res.obj.VehicleTerminals.length
        //     ? Object.assign(
        //         JSON.parse(
        //           JSON.stringify(this.vehicleInfo.curTerminal.children)
        //         ),
        //         res.obj.VehicleTerminals
        //       )
        //     : JSON.parse(JSON.stringify(this.vehicleInfo.curTerminal.children));
        // this.groupStr = res.obj.dvehicleInfo.groupName;
        // this.getMachineryEquipmentTypeList();
        // if (this.vehicleInfo.cameraLine) {
        //   this.cameraLineList = [];
        //   let cameraLineList = JSON.parse(this.vehicleInfo.cameraLine);
        //   let cameraNameList = this.vehicleInfo.cameraCH
        //     ? JSON.parse(this.vehicleInfo.cameraCH)
        //     : [];
        //   cameraLineList.map((val, ind) => {
        //     this.cameraLineList.push({
        //       camera: val,
        //       name: cameraNameList[ind] || "",
        //     });
        //   });
        // }
        // if (this.vehicleInfo.cameraLine) this.vehicleInfo.cameraLine = JSON.parse(this.vehicleInfo.cameraLine);
        // if (this.vehicleInfo.productionDate)
        //   this.vehicleInfo.productionDate = new Date(
        //     this.vehicleInfo.productionDate
        //   )
        //     .toLocaleDateString()
        //     .replace(/\//g, "-");
        // if (this.vehicleInfo.purchaseDate)
        //   this.vehicleInfo.purchaseDate = new Date(
        //     this.vehicleInfo.purchaseDate
        //   )
        //     .toLocaleDateString()
        //     .replace(/\//g, "-");
        // if (this.vehicleInfo.activationTmeStr) {
        //   this.vehicleInfo.activationTme = this.vehicleInfo.activationTmeStr;
        // }
      });
    },
    validateData() {
      let {
        plate,
        machineryProductType,
        machineryEquipmentType,
        curTerminal,
        vehicleTerminals,
        frameNo,
        machineryProductName,
        isCommercialVehicles,
      } = this.vehicleInfo;
      let singList = ["!", "@", "#", "￥", "%", "……", "&", "*"];
      if (plate) {
        if (plate.length > 30) {
          this.$message({
            type: "warning",
            message: "设备名称不能超过30个字符",
          });
          return false;
        } else if (singList.some((res) => plate.includes(res))) {
          this.$message({
            type: "warning",
            message: "设备名称不能带特殊符号",
          });
          return false;
        }
      } else {
        this.$message({
          type: "warning",
          message: "请输入设备名称",
        });
        return false;
      }
      if (!machineryProductType) {
        this.$message({
          type: "warning",
          message: "请选择设备类型",
        });
        return false;
      }

      if (isCommercialVehicles !== false && isCommercialVehicles !== true) {
        this.$message({
          type: "warning",
          message: "请选择设备性质",
        });
        return false;
      }

      if (!machineryEquipmentType) {
        this.$message({
          type: "warning",
          message: "请选择设备种类",
        });
        return false;
      }

      if (!frameNo) {
        this.$message({
          type: "warning",
          message: "请输入机身编号(车架号)",
        });
        return false;
      }

      if (!machineryProductName && isCommercialVehicles) {
        this.$message({
          type: "warning",
          message: "请输入车牌号",
        });
        return false;
      }

      if (!this.groupStr) {
        this.$message({
          type: "warning",
          message: "请选择车组",
        });
        return false;
      }
      if (!curTerminal) {
        this.$message({
          type: "warning",
          message: "请选择终端产品版本",
        });
        return false;
      }

      if (
        vehicleTerminals.some((res) => {
          return res.terminalNo === "" || res.terminalSim === "";
        })
      ) {
        this.$message({
          type: "warning",
          message: "请完善终端信息",
        });
        return false;
      }

      return true;
    },
    onSaveData() {
      if (!this.validateData()) return;
      let data = {
        vehicle: this.vehicleInfo,
        extraData: {},
        VehicleAuthor: {},
        autoauth: 0,
        flag: 0,
      };
      if (this.isEdit) {
        data.vehicleId = this.vehicleId;
      }
      if (this.cameraLineList && this.cameraLineList.length) {
        data.cameraLine = [];
        data.cameraCH = [];
        this.cameraLineList.map((val) => {
          data.cameraLine.push(val.camera);
          data.cameraCH.push(val.name);
        });
        data.vehicle.cameraLine = JSON.stringify(data.cameraLine);
        data.vehicle.cameraCH = JSON.stringify(data.cameraCH);
      }
      addOrUpdateVehicle(data, !this.isEdit).then((res) => {
        if (res.flag === 1) {
          if (res.msg)
            this.$message({
              type: "success",
              message: res.msg,
            });
          this.onModalChange(false, "on-ok");
        } else {
          if (res.msg)
            this.$message({
              type: "warning",
              message: res.msg,
            });
        }
      });
    },
    validateForm(type = 1) {
      let form = {};
      if (type == 1) {
        form = this.vehicleInfo;
      } else if (type == 2) {
        form = this.vehicleInfo.terminalV2List;
      } else {
        form = this.vehicleInfo;
      }
      let mianRules = [];
      let rules = [
        {
          keys: ["plate"],
          msg: "设备名称不能为空",
        },
        {
          keys: ["groupId"],
          msg: "请选择车组",
          vfun: () => {
            if (this.vehicleInfo.groupId == -1 || !this.vehicleInfo.groupId) {
              return false;
            }
            return true;
          },
        },
        { keys: ["machineryEquipmentType"], msg: "请选择设备类型" },
        { keys: ["frameNo"], msg: "请输入机身编号（车架号）" },
        {
          keys: ["sbPlate"],
          msg: "请输入车牌号",
          vfun: () => {
            if (
              this.vehicleInfo.isCommercialVehicles &&
              !this.vehicleInfo.sbPlate
            ) {
              return false;
            }
            return true;
          },
        },
        {
          keys: ["customerId", "customerName"],
          msg: "请选择客户",
        },
        {
          keys: ["installationServiceProvider"],
          msg: "安装服务商",
          vfun: () => {
            if (
              this.vehicleInfo.installationType == 2 &&
              !this.vehicleInfo.installationServiceProvider
            ) {
              return false;
            }
            return true;
          },
        },
        {
          keys: ["installPerson"],
          msg: "请输入安装人员",
        },
        {
          keys: ["installDate"],
          msg: "请选择安装时间",
        },
        {
          keys: ["vehicleVersion"],
          msg: "请选择产品版本",
        },
      ];
      let rules2 = [
        {
          keys: ["id"],
          msg: "请选择终端",
        },
        {
          keys: ["sim"],
          msg: "请输入终端SIM卡号",
        },
        {
          keys: ["simTypeValue"],
          msg: "请选择终端SIM卡供应商",
        },
        {
          keys: ["activationTime"],
          msg: "请选择激活日期",
        },
        {
          keys: ["expireTime"],
          msg: "请选服务到期时间",
        },
      ];
      let validate = false;
      if (type == 1) {
        mianRules = rules;
        validate = mianRules.some((ru) => {
          if (ru.vfun) {
            if (!ru.vfun()) {
              this.$message({
                type: "warning",
                message: ru.msg,
              });
              return true;
            }
          } else {
            return ru.keys.some((key) => {
              let val = form[key];
              if (!val) {
                this.$message({
                  type: "warning",
                  message: ru.msg,
                });
                return true;
              }
            });
          }
        });
      } else if (type == 2) {
        mianRules = rules2;
        validate = form.some((item) => {
          return mianRules.some((ru) => {
            if (ru.vfun) {
              if (!ru.vfun()) {
                this.$message({
                  type: "warning",
                  message: ru.msg,
                });
                return true;
              }
            } else {
              return ru.keys.some((key) => {
                let val = item[key];
                if (!val) {
                  this.$message({
                    type: "warning",
                    message: `${ru.msg}(${item.deviceTypeName})`,
                  });
                  return true;
                }
              });
            }
          });
        });
      }
      return !validate;
    },
    submitData() {
      this.handleFile(3);
      this.vehicleInfo.cameraLine = [];
      this.vehicleInfo.cameraCH = [];
      this.vehicleInfo?.cameraArray?.forEach((item) => {
        this.vehicleInfo.cameraLine.push(item.cameraLine);
        this.vehicleInfo.cameraCH.push(item.cameraCH);
      });
      this.vehicleInfo.cameraLine = JSON.stringify(this.vehicleInfo.cameraLine);
      this.vehicleInfo.cameraCH = JSON.stringify(this.vehicleInfo.cameraCH);
      this.handleOldData();
      if (this.validateForm() && this.validateForm(2)) {
        addOrEditEquipment(this.vehicleInfo).then((res) => {
          if (res.flag == 1) {
            this.onModalChange(false, "on-ok");
          }
        });
        // console.log(this.vehicleInfo);
      }
    },
    initvehicleInfo() {
      // return {
      //   purchaseDate: "", //购买日期
      //   remark: "", //备注内容
      //   manufactureFactory: "", //生产厂家
      //   deviceSize: "", //外形尺寸
      //   productionDate: "", //出场日期
      //   frameNo: "", //机身编号（车架号）
      //   engineNo: "", //发动机编号
      //   seat: "", //整备质量（吨位/吨）
      //   purchasePrice: "", //购置价
      //   machineryProductNum: "", //设备型号
      //   isCommercialVehicles: false, //是否商车类型
      //   machineryProductName: "", //车牌号
      //   brand: "", //设备品牌
      //   machineryProductType: "", //设备类型(产品线种类)
      //   machineryEquipmentType: "", //设备种型(产品类型)
      //   installPerson: "", //安装人员（安装工）
      //   installDate: "", //安装时间
      //   activationTme: "", //激活时间
      //   equipmentType: "", //产品版本（设备种类）
      //   plate: "", //设备名称
      //   vehicleTerminals: [], //终端集合
      //   cameraNum: null, //摄像头个数
      //   cameraLine: null, //摄像头路数
      //   owner: "", //车主
      //   license: "", //证件号码（紧急联系人）
      //   phone: "", //联系电话
      //   orderNumber: "", //工单编号
      // };
      return {
        frameNo: "", //机身编号（车架号）
        installPerson: "", //安装人员（安装工）
        installDate: dayjs().format("YYYY-MM-DD 00:00:00"), //安装时间
        isCommercialVehicles: false, //(设备性质)是否为商车
        plate: "", //设备名称
        serviceCharge: "", //服务费
        remark: "", //备注
        sbPlate: "", //
        owner: "", //车主
        license: "", //紧急联系电话
        phone: "", //车主联系电话
        groupId: "", //车组ID
        vehicleId: "", //车辆id
        machineryProductType: "", //机械产品类型
        machineryEquipmentType: "", //机械设备类型
        customerId: "", //所属客户id
        customerName: "", //所属客户
        workingConditionKey: "workingCondition", //作业工况字典key
        workingConditionValue: "", //作业工况字典value
        installationType: 1, //安装类型(1:公司、2:外包)
        onState: 1, //使用状态(1:正常，2:维修，3:停用)
        vehicleModel: "", //车辆型号
        installationServiceProvider: "", //安装服务商
        cameraLine: [], //摄像头路数
        cameraCH: [],
        vehicleVersion: null,
        vehicleBrand: "",
        // vehicleVersion: {
        //   id: '', //主键id
        //   versionName: "", //版本名称
        //   equipmentNature: false, //设备性质(0:工机，1:商车）
        //   terminalType: "", //终端类型(多个,隔开)
        //   isEnable: '', //是否启用(0：不启用，1：启用)
        // }, //版本信息
        attachmentList: [], //附件
        terminalV2List: [],
      };
    },
    handleCameras(e) {
      // this.vehicleInfo.cameraNum = e.length;
      // this.vehicleInfo.cameraLine = e;
    },
    handleDelCamera(e) {
      if (e == -1) {
        this.cameraLineList.push({
          camera: "",
          name: "",
        });
      } else {
        this.cameraLineList.splice(e, 1);
      }
    },
    handleDelCamera2(e) {
      if (e == -1) {
        this.vehicleInfo.cameraArray.push({
          cameraLine: "",
          cameraCH: "",
        });
      } else {
        this.vehicleInfo.cameraArray.splice(e, 1);
      }
    },
    handleTerminalSelect(e) {
      this.vehicleInfo.curTerminal = e;
      this.vehicleInfo.equipmentType = e.label;
      this.vehicleInfo.vehicleTerminals = e.children;
      if (e && e.label.indexOf("智慧") === 0) {
        this.vehicleInfo.cameraNum = "4";
        this.vehicleInfo.cameraLine = [1, 2, 3, 4];
      } else {
        this.vehicleInfo.cameraNum = null;
        this.vehicleInfo.cameraLine = null;
      }
      this.$forceUpdate();
    },
    handleTerminalSelect2(item) {
      this.vehicleInfo.vehicleVersion = item;
      this.$set(
        this.vehicleInfo,
        "terminalV2List",
        this.generateTerminalForm(item.terminalType)
      );
      // console.log(this.vehicleInfo);

      // 根据终端类型查终端列表
      this.getTerminalListByDeviceType2();
    },
    // 获取产品类型列表
    getMachineryProductTypeList() {
      getDictList({ type: "1" })
        .then((res) => {
          if (res.flag === 1) {
            this.machineryProductTypeList = res.rows;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onConfirmClick(group) {
      const { groupId, groupName, groupType } = group;
      this.vehicleInfo.groupId = ~~groupId;
      //this.vehicleInfo.groupName = groupName;
      this.groupStr = groupName;
      if (!this.vehicleInfo.vehicleShape)
        this.vehicleInfo.vehicleShape = groupType || "其他";
    },
    getBrandList() {
      getBrandList({
        dictType: "vehicle_brand",
        platform: "PC",
      }).then((res) => {
        if (res.flag === 1) {
          this.brandList = res.obj;
        }
      });
    },
    // 获取设备类型列表
    getMachineryEquipmentTypeList() {
      getDictType({ dictType: this.vehicleInfo.machineryProductType })
        .then((res) => {
          if (res.flag === 1) {
            this.machineryEquipmentTypeList = res.obj;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择产品类型
    // selectMachineryProductType() {
    //   this.vehicleInfo.machineryEquipmentType = "";
    //   this.getMachineryEquipmentTypeList();
    // },
    clearMachineryProductType() {
      this.machineryEquipmentTypeList = [];
    },
    handleInput() {
      this.$forceUpdate();
    },
    onModalChange(modal, eventName = "on-cancel", ...options) {
      // this.groupStr = "";
      this.$emit("change", modal);
      this.$emit(eventName, ...options);
    },
    /////////////////////////////////////
    // 获取字典
    dictionaryBatchGet() {
      //this.getMachineryEquipmentTypeList();
      this.getMachineryProductTypeList();
      this.getBrandList();
      let keys = [
        "simCardType",
        "workingCondition",
        "CXvehicleType",
        "vehicleType",
        "vehicleBrand",
        "CXvehicleBrand",
      ];
      dictionaryBatch({ codes: keys.join(",") }).then((res) => {
        keys.forEach((key) => {
          this.$set(this.dictList, key, res?.data[key] || []);
        });
      });
    },
    // 处理附件数据
    handleFile(type = 1) {
      // 1 添加 2 修改 3 提交数据处理
      if (type == 1) {
        this.upLoadList = [];
        machineUploadType.forEach((item) => {
          this.upLoadList.push({
            ...item,
            attachments: [
              // {
              //   attachmentName: "",
              //   attachmentUrl: "",
              //   fileType: "",
              // },
            ],
          });
        });
      } else if (type == 2) {
        this.upLoadList.forEach((item) => {
          this.vehicleInfo?.attachmentList?.forEach((item2) => {
            if (item.attachmentType == item2.attachmentType) {
              item.attachments.push(item2);
            }
          });
        });
      } else if (type == 3) {
        this.vehicleInfo.attachmentList = [];
        this.upLoadList.forEach((item) => {
          item.attachments.forEach((file) => {
            this.vehicleInfo.attachmentList.push({
              ...file,
              attachmentType: item.attachmentType,
              vehicleId: this.vehicleId || "",
            });
          });
        });
      }
    },
    // 获取产品版本模板
    getProductTemplate(type) {
      let temp = {
        id: "", //主键id
        vehicleId: "", //车辆id
        terminalNo: "", //终端编号
        mainFlag: false, //是否是主终端 true/false (包含OBD的设备  把obd设置为主终端)
        terminalTypeKey: "protocolType", //协议类型字典key
        terminalTypeValue: 1, //协议类型字典value
        terminalType: "", //终端类型(多个,隔开)
        deviceType: "", //1.视频终端(OVP) 2.OBD终端 3.有线GPS 4.无线GPS 5.PFT 6.BSD 7.AEB
        manufactorTypeKey: "terminalVendors", //终端供应商字典key
        manufactorTypeValue: "", //终端供应商字典value
        manufactorType: "", //硬件厂商类型
        terminalSerialNumber: "", //终端序列号
        sim: "", //sim卡号
        simTypeKey: "simCardType", //sim卡供应商字典key
        simTypeValue: "", //sim卡供应商字典value
        activationTime: dayjs().format("YYYY-MM-DD 00:00:00"), //激活日期
        expireTime: "", //服务到期时间
      };
      return temp;
    },
    // 处理摄像头数据
    handleCameraData(init = true, initNum = 4) {
      let cameraArray = [];
      if (!init) {
        let cameraLines = JSON.parse(this.vehicleInfo.cameraLine || "[]");
        let cameraCHs = JSON.parse(this.vehicleInfo.cameraCH || "[]");
        cameraLines.forEach((item, index) => {
          cameraArray.push({
            cameraLine: item,
            cameraCH: cameraCHs[index] || "",
          });
        });
      } else {
        this.vehicleInfo.cameraLine = [];
        this.vehicleInfo.cameraCH = [];
        //默认名字
        let cameraName = ["右摄像头", "后摄像头", "左摄像头", "前摄像头"];
        for (let i = 1; i <= initNum; i++) {
          cameraArray.push({
            cameraLine: i,
            cameraCH: i <= cameraName.length ? cameraName[i - 1] : "",
          });
        }
      }
      this.$set(this.vehicleInfo, "cameraArray", cameraArray);
      return cameraArray;
    },
    changeEquipmentNature(type = false) {
      getVersionListByEquipmentNature({
        en: type,
      }).then((res) => {
        this.dictList.productList =
          res?.obj?.map((item) => {
            return {
              ...item,
              dictValue: item.versionName,
              dictKey: item.id,
            };
          }) || [];
      });
    },
    handleSelectTerminalV2(terminal, oldTerminal, index) {
      // console.log(terminal, oldTerminal, index);
      let tv = { ...oldTerminal };
      delete tv.id;
      let res = {};
      Object.assign(res, oldTerminal, terminal);
      // console.log(res);
      // 替换终端列表
      this.vehicleInfo.terminalV2List.splice(index, 1, res);
      this.isMatching = false;
    },
    //根据终端类型查终端列表
    async getTerminalListByDeviceType(type, id = "", index) {
      // if (!this.dictList[`deviceTypeList${type}`]) {
      this.$set(this.dictList, `deviceTypeList${type}${index}`, []);
      let res = await getTerminalListByEquipmentType({ deviceType: type });
      res?.obj?.forEach((item) => {
        this.dictList[`deviceTypeList${type}${index}`].push({
          ...item,
          dictValue: item.terminalNo,
          dictKey: item.terminalNo,
        });
        // return {
        //   ...item,
        //   dictValue: item.terminalNo,
        //   dictKey: item.terminalNo,
        // };
      });
      //this.$set(this.dictList, `deviceTypeList${type}`, typeList);
      // }
      return true;
    },
    getTerminalListByDeviceType2(isEdit = false) {
      let types = [];
      this.vehicleInfo.terminalV2List.forEach(async (item, index) => {
        // if (!types.includes(item.deviceType)) {
        //   types.push(item.deviceType);
        // }
        await this.getTerminalListByDeviceType(item.deviceType, item.id, index);
        if (isEdit) {
          this.dictList[`deviceTypeList${item.deviceType}${index}`].push({
            ...item,
            dictValue: item.terminalNo,
            dictKey: item.terminalNo,
          });
        }
      });
      // types.forEach((item) => {
      //   this.getTerminalListByDeviceType(item, isEdit);
      // });
    },
    // 根据终端类型生成表单
    generateTerminalForm(types = "") {
      let list = types.split(",");
      let res = [];
      list.forEach((item) => {
        let tm = this.getProductTemplate();
        tm.vehicleId = this.vehicleId;
        tm.deviceType = Number(item);
        // 根据终端类型查终端列表
        // this.getTerminalListByDeviceType(item);
        res.push(tm);
      });
      // 根据deviceType设置主终端
      // let mainTm = null;
      // res.forEach((item) => {
      //   ProductTerminal.find((dict) => {
      //     if (dict.value === item.deviceType) {
      //       item.majorGrade = dict.majorGrade;
      //       item.deviceTypeName = dict.label;
      //       item.isVideo = dict.isVideo;
      //       if (item.isVideo) {
      //         this.handleCameraData();
      //       }
      //       return;
      //     }
      //   });
      //   if (!mainTm) {
      //     item.mainFlag = true;
      //     mainTm = item;
      //   } else {
      //     if (mainTm.majorGrade > item.majorGrade) {
      //       mainTm.mainFlag = false;
      //       item.mainFlag = true;
      //       mainTm = item;
      //     }
      //   }
      // });
      this.fillTerminalData(res);
      return res;
    },
    //填充终端数据
    fillTerminalData(res = [], init = true) {
      let mainTm = null;
      let localProductTerminal = this.vehicleInfo.isCommercialVehicles
        ? ProductTerminalCommerce
        : ProductTerminal;
      res.forEach((item) => {
        localProductTerminal.find((dict) => {
          if (dict.value === item.deviceType) {
            item.majorGrade = dict.majorGrade;
            item.deviceTypeName = dict.label;
            item.isVideo = dict.isVideo;
            if (item.isVideo) {
              this.handleCameraData(init);
            }
            return;
          }
        });
        if (!mainTm) {
          item.mainFlag = true;
          mainTm = item;
        } else {
          if (mainTm.majorGrade > item.majorGrade) {
            mainTm.mainFlag = false;
            item.mainFlag = true;
            mainTm = item;
          }
        }
      });
      //console.log(res, this.dictList);
    },
    customerQuerySearchAsync(queryString, cb) {
      getEquipmentCustomerList({ customerName: queryString }).then((res) => {
        cb(
          res.obj.map((item) => {
            return {
              ...item,
              value: item.customerName,
            };
          })
        );
      });
    },
    fetchTerminalV2(e, cb, item, index) {
      const list = (
        this.dictList[`deviceTypeList${item.deviceType}${index}`].filter(
          (vv) => vv.dictValue.indexOf(e) > -1
        ) || []
      ).map((vav) => {
        return {
          ...vav,
          value: vav.dictValue || "",
        };
      });
      if (list && list.length && list[0].value == e) {
        let tv = { ...item };
        delete tv.id;
        let res = {};
        Object.assign(res, item, list[0]);
        // console.log(res);
        // 替换终端列表
        this.vehicleInfo.terminalV2List.splice(index, 1, res);
        this.isMatching = false;
      } else if (e) {
        this.isMatching = true;
      }
      cb(list);
    },
    handleSelectCustomer(item) {
      this.vehicleInfo.customerId = item.customerId;
    },
    // 处理旧数据兼容新逻辑
    handleOldData(data) {
      this.vehicleInfo.machineryProductType = this.vehicleInfo
        .isCommercialVehicles
        ? "CXvehicleType"
        : "vehicleType";
    },
    // 切换设备性质清空终端信息，设备类型
    changeEquipmentNatureClear() {
      this.vehicleInfo.vehicleVersion = {};
      this.vehicleInfo.terminalV2List = [];
      this.vehicleInfo.machineryProductType = ""; //机械产品类型
      this.vehicleInfo.machineryEquipmentType = ""; //机械设备类型
    },
  },
};
</script>
<style lang="scss" scoped>
.header-title {
  background: #ffffff;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  padding: 15px 20px;
  line-height: normal;
}

.vehicle-info-content {
  height: 650px;
  font-size: 14px;
  overflow: auto;
  .content {
    overflow: auto;
    padding: 0 0 30px 0;
    .basic,
    .install,
    .terminal,
    .owner {
      margin-bottom: 20px;
      > .title {
        //   border-bottom: 1px solid #e7e7e7;
        padding: 10px 10px;
        position: relative;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          width: 5px;
          height: 16px;
          background-color: #4b7bca;
          border-radius: 3px;
        }
        &::before {
          content: "";
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          left: 80px;
          border-bottom: 1px solid #666;
        }
      }
      > .form {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 20px;
        .item {
          position: relative;
          width: 33%;
          margin: 6px 0;
          .label {
            margin-bottom: 5px;
          }
          .required::before {
            position: absolute;
            left: -10px;
            top: -5px;
            content: "*";
            color: red;
            font-weight: bold;
            font-size: 20px;
          }
          .info {
            width: 70%;
          }
          .car-group-dropdown {
            & > div:first-child {
              width: 100%;
            }
          }
        }
      }
    }
    .terminal {
      .terminal-info {
        display: flex;
        flex-wrap: wrap;
        .info-item {
          width: 30%;
          padding: 10px;
          background-color: #e2f1ff;
          border: 1px solid #d7e2f3;
          margin-right: 30px;
          box-shadow: 0px 2px 6px 1px rgba(66, 120, 201, 0.18);
          border-radius: 10px 10px 10px 10px;
          margin-bottom: 10px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          &:last-child {
            margin-right: 0;
          }
          .title {
            //padding: 0 0 5px 0;
            // border-bottom: 1px solid #e7e7e7;
            margin-bottom: 10px;
            position: relative;
            font-weight: bold;
            font-size: 14px;
            color: #4278c9;
            .host {
              position: absolute;
              right: 0;
              font-size: 12px;
              background-image: linear-gradient(#a5caff, #6ba8ff);
              padding: 2px 6px;
              border-radius: 0 5px 0 5px;
              color: #fff;
            }
            .host2 {
              font-size: 12px;
              background-image: linear-gradient(#a5caff, #6ba8ff);
              padding: 2px 6px;
              border-radius: 0 5px 0 5px;
              color: #fff;
            }
          }
          .form {
            background-color: #ffffff;
            padding: 16px 8px;
            border-radius: 4px 4px 4px 4px;
            width: 100%;
            display: flex;
            flex-direction: column;
            .item {
              display: flex;
              align-items: center;
              margin-top: 5px;
              font-size: 12px;
              position: relative;
              .label {
                min-width: 80px;
                text-align: right;
                position: relative;
              }
              .label::before {
                position: absolute;
                left: -8px;
                top: 0;
                content: "*";
                color: red;
                font-weight: bold;
                font-size: 20px;
              }
            }
            .info {
              flex: 1;
            }
          }
        }
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    z-index: 55;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e8eaec;
    background: #fff;
    & > :first-child {
      margin-right: 5px;
      width: 80px;
    }
    & > :last-child {
      margin-left: 5px;
      width: 80px;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
::v-deep .el-dialog__header {
  padding: 0;
  position: relative;
  .el-dialog__headerbtn {
    top: 50%;
    transform: translateY(-50%);
    .el-dialog__close {
      color: #333333;
      font-size: 20px;
    }
  }
}
::v-deep .el-dialog {
  border-radius: 10px;
  overflow: hidden;
}
////////////////////////////////////
.attachments {
  &-tips {
    font-weight: 400;
    font-size: 14px;
    color: #999999;
  }
  &-file {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    &-item {
      margin-top: 24px;
      margin-right: 24px;
      min-width: 300px;
    }
  }
}
.car-type-select {
  display: flex;
  justify-content: space-between;
  &-item {
    width: 115px;
    height: 32px;
    background: #efefef;
    border-radius: 4px 4px 4px 4px;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &-item-active {
    background: #deeeff;
    color: #0080ff;
  }
}
.custom-scrollbar .el-scrollbar__bar {
  /* 自定义滚动条样式 */
}
.matching-box {
  border: 1px solid red;
}
</style>
